.navbar-wrapper {
	height: 50px;
	display: flex;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	justify-content: center;
	background-color: black;
	button {
		color: white;
		width: 100px;
		background: none;
		border: none;
		cursor: pointer;
	}
	button:hover {
		background-color: #1f003b;
	}
}
