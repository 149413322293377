@media only screen and (min-width: 601px), screen and (max-width: 1200) {
	.about-wrapper {
		display: flex;
		justify-content: center;
		height: calc(100vh - 50px);
		flex-direction: column;
		min-height: 700px;
		.picture {
			border-radius: 150px;
			border-color: white;
			border: 1px solid;
			width: 200px;
			align-self: center;
		}
		.blurb {
			width: 60%;
			align-self: center;
			font-size: 18px;
		}
		.contact-me {
			margin-bottom: 25px;
		}
		.contact-links {
			width: 200px;
			display: flex;
			justify-content: space-evenly;
			margin: 0px auto;
			.contact {
				height: 50px;
				width: 50px;
				cursor: pointer;
			}
		}
	}
}

@media only screen and (min-width: 1201px) {
	.about-wrapper {
		padding: 25vh 0px 0px 0px;
		display: flex;
		flex-direction: column;
		height: calc(75vh - 50px);
		.picture {
			border-radius: 150px;
			border-color: white;
			border: 1px solid;
			width: 200px;
			align-self: center;
		}
		.blurb {
			width: 60%;
			align-self: center;
			font-size: 18px;
		}
		.contact-links {
			width: 200px;
			display: flex;
			justify-content: space-evenly;
			margin: 0px auto;
			.contact {
				height: 50px;
				width: 50px;
				cursor: pointer;
			}
		}
		
	}
}

@media only screen and (max-width: 600px) {
	.about-wrapper {
		padding: 5vh 0px 0px 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: calc(95vh - 50px);
		margin-bottom: 35px;
		.picture {
			border-radius: 150px;
			border-color: white;
			border: 1px solid;
			width: 150px;
			align-self: center;
		}
		.blurb {
			width: 80%;
			align-self: center;
			font-size: 18px;
		}
		.contact-me {
			margin-bottom: 25px;
		}
		.contact-links {
			width: 200px;
			display: flex;
			justify-content: space-evenly;
			margin: 0px auto;
			.contact {
				height: 50px;
				width: 50px;
				cursor: pointer;
			}
		}
	}
}
