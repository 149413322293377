@media only screen and (min-width: 601px), screen and (max-width: 1200) {
	.skills-wrapper {
		padding-top: 0px;
		min-height: 90vh;
		display: flex;
		flex-direction: column;
		margin: auto;
		width: 60%;
		.technology-wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: 25px;
			img {
				margin: 10px;
				height: 75px;
				width: 75px;
				margin-top: 10px;
				text-align: center;
				align-self: center;
				cursor: pointer;
				&.aws {
					background-color: #ff9900;
					border-radius: 10px;
				}
			}
			.technoloy-blurb {
				width: 40vw;
				min-width: 325px;
			}
		}
	}
}

@media only screen and (min-width: 1201px) {
	.skills-wrapper {
		padding-top: 100px;
		justify-content: center;
		height: 100vh;
		.technology-wrapper {
			img {
				height: 90px;
				width: 90px;
				margin-top: 10px;
				text-align: center;
				cursor: pointer;
			}
			.technoloy-blurb {
				width: 40vw;
				min-width: 400px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.skills-wrapper {
		padding-top: 125px;
		min-height: 90vh;
		.technology-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 25px;
			img {
				margin: 10px;
				height: 75px;
				width: 75px;
				margin-top: 10px;
				text-align: center;
				cursor: pointer;
				&.aws {
					background-color: #ff9900;
					border-radius: 10px;
				}
			}
			.technoloy-blurb {
				width: 85vw;
			}
		}
	}
}
